@import "rsuite/dist/rsuite.css";
@import "fonts.css";
body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: Manrope, sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;

}
.rs-drawer-left.rs-drawer-xs, .rs-drawer-right.rs-drawer-xs {
  min-width: 233px;
  width: 50vw;
}
.rs-drawer-left.rs-drawer-xs, .rs-drawer-right.rs-drawer-xs .navigation {
  min-width: 233px;
  width: 20vw;
  max-width: 304px;
}
.rs-drawer-body {
  padding: 30px;
}
.rs-drawer-body-close {
  display: none;
}
.rs-modal-wrapper {
  display: flex;
  align-items: center;
}
.rs-modal-sm {
  width: 360px;
  max-width: calc(100% - 10px);
  height: 310px;
}
@media screen and (max-width: 768px) {
  .rs-modal-sm {
    margin-bottom: 20vh;
  }
}
.rs-modal-md .rs-modal-body {
    max-height: 700px!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button {
  background: transparent;
}

/* Print page */
.printableTable {
   width: 95%;
   height: 95%;
  margin: 0 auto;
   border-collapse: collapse;
 }

.printableTable tr td {
  padding: 5px;
}

.printableRow {
  page-break-inside: avoid;
}

.printableRow td {
  padding: 5px;
  border-top: 1px solid #ff9b41;
}