@font-face{font-display: swap; font-family:Chalkboard;src:url(./assets/fonts/8bb156b7881e73b257327180880ce355.woff) format("woff"),url(./assets/fonts/0e2b2bb5a912b5af53fc9a166f6f9bfa.ttf) format("truetype"),url(./assets/fonts/aba0212fa113307952ebdc30fd4c19ef.otf) format("opentype");font-weight:400;font-style:normal}
@font-face{font-display: swap; font-family:Helvetica;src:url(./assets/fonts/Helvetica.ttf) format("truetype"),url(./assets/fonts/helvetica-rounded-bold-5871d05ead8de.otf) format("opentype");font-weight:400;font-style:normal}
@font-face {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    src: local('Arial Regular'), url(./assets/fonts/ARIAL.woff) format('woff');
    font-display: swap;
}



@font-face {
    font-family: "Nunito Sans";
    font-weight: 200;
    font-style: normal;
    src: url("./assets/fonts/pe03MImSLYBIv1o4X1M8cc9yAv5qWVAgVol-.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 200;
    font-style: italic;
    src: url("./assets/fonts/pe01MImSLYBIv1o4X1M8cce4GxZrU1QCU5l-06Y.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 300;
    font-style: normal;
    src: url("./assets/fonts/pe03MImSLYBIv1o4X1M8cc8WAf5qWVAgVol-.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 300;
    font-style: italic;
    src: url("./assets/fonts/pe01MImSLYBIv1o4X1M8cce4G3JoU1QCU5l-06Y.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 400;
    font-style: normal;
    src: url("./assets/fonts/pe0qMImSLYBIv1o4X1M8cfe6Kdpickwp.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 400;
    font-style: italic;
    src: url("./assets/fonts/pe0oMImSLYBIv1o4X1M8cce4I95Ad1wpT5A.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 600;
    font-style: normal;
    src: url("./assets/fonts/pe03MImSLYBIv1o4X1M8cc9iB_5qWVAgVol-.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 600;
    font-style: italic;
    src: url("./assets/fonts/pe01MImSLYBIv1o4X1M8cce4GwZuU1QCU5l-06Y.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 700;
    font-style: normal;
    src: url("./assets/fonts/pe03MImSLYBIv1o4X1M8cc8GBv5qWVAgVol-.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 700;
    font-style: italic;
    src: url("./assets/fonts/pe01MImSLYBIv1o4X1M8cce4G2JvU1QCU5l-06Y.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 800;
    font-style: normal;
    src: url("./assets/fonts/pe03MImSLYBIv1o4X1M8cc8aBf5qWVAgVol-.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 800;
    font-style: italic;
    src: url("./assets/fonts/pe01MImSLYBIv1o4X1M8cce4G35sU1QCU5l-06Y.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 900;
    font-style: normal;
    src: url("./assets/fonts/pe03MImSLYBIv1o4X1M8cc8-BP5qWVAgVol-.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Nunito Sans";
    font-weight: 900;
    font-style: italic;
    src: url("./assets/fonts/pe01MImSLYBIv1o4X1M8cce4G1ptU1QCU5l-06Y.ttf");
    font-display: swap;
}

/* manrope-200 - latin */
@font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 200;
    src: url('./assets/fonts/Manrope-ExtraLight.ttf')
}
/* manrope-300 - latin */
@font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/Manrope-Light.ttf')
}
/* manrope-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Manrope-Regular.ttf')
}
/* manrope-500 - latin */
@font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Manrope-Medium.ttf')
}
/* manrope-600 - latin */
@font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/Manrope-SemiBold.ttf')
}
/* manrope-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/Manrope-Bold.ttf')
}
/* manrope-800 - latin */
@font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/fonts/Manrope-ExtraBold.ttf')
}